import React from 'react';
import styled from 'styled-components';
import { siteColors, styleVariables } from '../../styles/Variables';
import Img from 'gatsby-image';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { Link } from 'gatsby';
dayjs.extend(advancedFormat);

const StyledUpcomingEventItem = styled.div`
  display: flex;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 900px) {
    flex-flow: column;
  }
`;

const StyledThumbnailImage = styled(Img)`
  height: 80px;
  background-color: #efefef;
  border-radius: ${styleVariables.borderRadius};
  margin-right: 20px;
  flex: 0 0 80px;
  width: 80px;

  @media (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 20px;
    border-radius: ${styleVariables.borderRadius};
    flex: 0 0 100px;
    width: 100%;
  }
  @media (max-width: 768px) {
    flex: 0 0 200px;
    height: 200px;
  }
`;

const StyledDate = styled.time`
  font-size: 0.9em;
`;

const StyledContent = styled.div`
  flex: 1 1 auto;
  align-items: center;
  display: flex;
`;
const StyledTitle = styled.h4`
  text-transform: uppercase;
  margin-top: 0;
  font-size: 1em;
  margin-bottom: 0;
  a {
    color: ${siteColors.primary};
    text-decoration: none;
  }
`;

interface Props {
  node: any;
  index: number;
}

const UpcomingEventItem = ({ node, index }: Props) => {
  const {
    frontmatter: {
      title,
      date,
      path,
      featuredImage: {
        childImageSharp: { fluid },
      },
    },
  } = node;
  return (
    <StyledUpcomingEventItem>
      <Link to={`/${path}`}>
        <StyledThumbnailImage fluid={fluid} />
      </Link>
      <StyledContent>
        <div>
          <StyledTitle title={title}>
            <Link to={`/${path}`}>
              {title.length > 30
                ? title.substring(0, 30).trim() + '...'
                : title}
            </Link>
          </StyledTitle>
          <StyledDate>
            {dayjs().add(index, 'd').add(6, 'M').format('MM/DD/YYYY')}
          </StyledDate>
        </div>
      </StyledContent>
    </StyledUpcomingEventItem>
  );
};

export default UpcomingEventItem;
