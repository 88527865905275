import React, { useContext } from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { styleVariables } from '../../styles/Variables';
import { ImageModal } from '../ImageModal';
import { SiteContext } from '../../state';

const StyledLatestGalleryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(calc(33% - 20px), 1fr));
  grid-template-columns: repeat(3, minmax(calc(33% - 20px), 1fr));
  @media (max-width: 768px) {
  }
  grid-gap: 20px;
`;

const StyledThumbnailImage = styled(Img)`
  width: 80px;
  height: 80px;
  background-color: #efefef;
  border-radius: ${styleVariables.borderRadius};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LatestGalleryGrid = () => {
  const { dispatch } = useContext(SiteContext);
  return (
    <StyledLatestGalleryGrid>
      <StaticQuery
        query={graphql`
          query {
            allFile(
              filter: { sourceInstanceName: { eq: "gallery" } }
              limit: 9
            ) {
              nodes {
                id
                sourceInstanceName
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                  }
                }
              }
            }
          }
        `}
        render={({ allFile: { nodes } }) => {
          return (
            <>
              {nodes.map((node: any, index: number) => {
                return (
                  <div
                    key={node.id}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => {
                      if (e.keyCode == 13) {
                        dispatch({
                          type: 'SET_IMAGE_MODAL_SRC',
                          payload: node.childImageSharp.fluid.src,
                        });
                        dispatch({
                          type: 'SET_IMAGE_MODAL_OPEN',
                          payload: true,
                        });
                      }
                    }}
                    onClick={() => {
                      dispatch({
                        type: 'SET_IMAGE_MODAL_SRC',
                        payload: node.childImageSharp.fluid.src,
                      });
                      dispatch({
                        type: 'SET_IMAGE_MODAL_OPEN',
                        payload: true,
                      });
                    }}
                  >
                    <StyledThumbnailImage
                      key={node.id}
                      alt="Gallery Image"
                      fluid={node.childImageSharp.fluid}
                    />
                  </div>
                );
              })}
            </>
          );
        }}
      />
    </StyledLatestGalleryGrid>
  );
};

export default LatestGalleryGrid;
