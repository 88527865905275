import React from 'react';
import styled from 'styled-components';
import { UpcomingEventItem } from '../UpcomingEventItem';
import { LatestGalleryGrid } from '../LatestGalleryGrid';
import { siteColors, styleVariables } from '../../styles/Variables';
import { graphql, StaticQuery } from 'gatsby';
import { LatestUpdates } from '../LatestUpdates';
import { HTag } from '../HTag';
import { StyledButton } from '../StyledButton';
import { LinkButton } from '../LinkButton';

const LatestSectionStyled = styled.section`
  width: 100%;
  clear: both;
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;

  h3 {
    text-transform: uppercase;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const LatestSectionItem = styled.div``;

const LatestSectionItemInner = styled.div`
  background-color: #fff;
  padding: 20px;
  border-radius: ${styleVariables.borderRadius};
  margin-bottom: 20px;
`;

const LatestSection = () => {
  return (
    <LatestSectionStyled>
      <LatestSectionItem>
        <HTag as="h3">Upcoming Events</HTag>
        <LatestSectionItemInner>
          <StaticQuery
            query={graphql`
              query {
                allMarkdownRemark(
                  filter: {
                    fields: { sourceInstanceName: { eq: "event" } }
                    frontmatter: { draft: { eq: false } }
                  }
                  limit: 3
                  sort: { order: ASC, fields: frontmatter___date }
                ) {
                  nodes {
                    id
                    frontmatter {
                      date
                      path
                      title
                      featuredImage {
                        childImageSharp {
                          fluid {
                            ...GatsbyImageSharpFluid_tracedSVG
                          }
                        }
                      }
                    }
                  }
                }
              }
            `}
            render={({ allMarkdownRemark: { nodes } }) => {
              return (
                <>
                  {nodes.map((node: any, index: number) => {
                    return (
                      <UpcomingEventItem
                        key={node.id}
                        index={index}
                        node={node}
                      />
                    );
                  })}
                </>
              );
            }}
          />
        </LatestSectionItemInner>
        <LinkButton
          style={{
            margin: '0 auto',
            display: 'block',
            width: 'fit-content',
            textTransform: 'uppercase',
          }}
          to="/events"
        >
          Read More Events
        </LinkButton>
      </LatestSectionItem>
      <LatestSectionItem>
        <HTag as="h3">Latest Updates</HTag>
        <LatestSectionItemInner>
          <LatestUpdates />
        </LatestSectionItemInner>
        <LinkButton
          style={{
            margin: '0 auto',
            display: 'block',
            width: 'fit-content',
            textTransform: 'uppercase',
          }}
          to="/updates"
        >
          Read More Updates
        </LinkButton>
      </LatestSectionItem>
      <LatestSectionItem>
        <HTag as="h3">Gallery</HTag>
        <LatestSectionItemInner>
          <LatestGalleryGrid />
        </LatestSectionItemInner>
      </LatestSectionItem>
    </LatestSectionStyled>
  );
};

export default LatestSection;
