import React from 'react';
import styled, { css } from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { PriceTag } from '../PriceTag';
import { styleVariables } from '../../styles/Variables';

interface SFSProps {
  HomePage?: boolean;
}
const StyledFoodSpecials = styled.section<SFSProps>`
  ${({ HomePage }) =>
    HomePage &&
    css`
      margin-top: -200px;
    `}
  z-index: 3;
  width: 100%;
  h1 {
    color: ${({ HomePage }) => (HomePage ? '#fff' : 'inherit')};
    text-transform: uppercase;
  }
`;

const StyledFoodSpecialList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(calc(25% - 20px), 1fr));
  grid-gap: 20px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(calc(50% - 20px), 1fr));
    grid-template-rows: repeat(2, minmax(calc(50% - 20px), 1fr));
  }
`;
const StyledFoodSpecialItem = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

interface SFSIIProps {
  onHomePage?: boolean;
}
const StyledFoodSpecialItemImg = styled(Img)<SFSIIProps>`
  height: 100%;
  width: 100%;
  border-radius: ${styleVariables.borderRadius};
  max-height: 230px;
  background-color: #efefef;
  overflow: hidden;
  margin-bottom: 10px;
  ${({ onHomePage }) =>
    onHomePage &&
    css`
      box-shadow: 0 0 5px #666;
    `}
`;

const StyledPriceTag = styled(PriceTag)`
  position: absolute;
  bottom: 45px;
  right: 10px;
`;

interface Props {
  onHomePage?: boolean;
}
const FoodSpecials = ({ onHomePage = true }: Props) => {
  return (
    <StyledFoodSpecials HomePage={onHomePage}>
      <h1>Specials</h1>
      <StyledFoodSpecialList>
        <StaticQuery
          query={graphql`
            query {
              allMarkdownRemark(
                filter: {
                  fields: { sourceInstanceName: { eq: "special" } }
                  frontmatter: { draft: { eq: false } }
                }
                limit: 4
                sort: { order: ASC, fields: frontmatter___order }
              ) {
                nodes {
                  id
                  frontmatter {
                    path
                    title
                    price
                    featuredImage {
                      childImageSharp {
                        fluid {
                          ...GatsbyImageSharpFluid_tracedSVG
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
          render={({ allMarkdownRemark: { nodes } }) => {
            return (
              <>
                {nodes.map((node: any, index: number) => {
                  console.log(
                    node.frontmatter.featuredImage.childImageSharp.fluid
                  );
                  return (
                    <StyledFoodSpecialItem key={node.id}>
                      <StyledFoodSpecialItemImg
                        onHomePage={onHomePage}
                        fluid={
                          node.frontmatter.featuredImage.childImageSharp.fluid
                        }
                      />
                      <StyledPriceTag data={node}>
                        {node.frontmatter.price}
                      </StyledPriceTag>
                      {node.frontmatter.title}
                    </StyledFoodSpecialItem>
                  );
                })}
              </>
            );
          }}
        />
      </StyledFoodSpecialList>
    </StyledFoodSpecials>
  );
};

export default FoodSpecials;
