import React from 'react';
import { LatestUpdateItem } from '../LatestUpdateItem';
import { graphql, StaticQuery } from 'gatsby';

const LatestUpdates = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            filter: {
              fields: { sourceInstanceName: { eq: "update" } }
              frontmatter: { draft: { eq: false } }
            }
            limit: 3
            sort: { order: DESC, fields: frontmatter___date }
          ) {
            nodes {
              id
              frontmatter {
                date
                path
                title
                featuredImage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_tracedSVG
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={({ allMarkdownRemark: { nodes } }) => {
        return (
          <>
            {nodes.map((node: any, index: number) => {
              return (
                <LatestUpdateItem key={node.id} index={index} node={node} />
              );
            })}
          </>
        );
      }}
    />
  );
};

export default LatestUpdates;
