import React, { ReactNode, useContext, useState } from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';
import { StyledButton } from '../StyledButton';
import { SiteContext } from '../../state';

interface SPTProps {
  added: boolean;
}
const StyledPriceTag = styled(StyledButton)<SPTProps>`
  font-size: 0.8em;
  font-weight: bold;
  padding: 10px 15px;
  ${({ added }) =>
    added &&
    css`
      background-color: #008400 !important;
      color: #fff !important;
    `}
`;
interface Props {
  children: ReactNode;
  data: any;
}
const PriceTag = ({ children, data, ...rest }: Props) => {
  const [adding, setAdding] = useState(false);
  const [added, setAdded] = useState(false);
  const { globalState, dispatch } = useContext(SiteContext);
  const handleClick = () => {
    const items = localStorage.getItem('ItemsInBag');
    setAdding(true);
    if (!items) {
      localStorage.setItem(
        'ItemsInBag',
        `[{"id": "${data.id}", "title": "${data.frontmatter.title}", "price": "${data.frontmatter.price}", "imgSrc": "${data.frontmatter.featuredImage.childImageSharp.fluid.src}"}]`
      );
    } else {
      const itemsToJson = JSON.parse(items);
      itemsToJson.push({
        id: data.id,
        title: data.frontmatter.title,
        price: data.frontmatter.price,
        imgSrc: data.frontmatter.featuredImage.childImageSharp.fluid.src,
      });

      const jsonToString = JSON.stringify(itemsToJson);
      localStorage.setItem('ItemsInBag', jsonToString);
    }
    setTimeout(() => {
      setAdding(false);
      setAdded(true);
      dispatch({ type: 'SET_CART_COUNT', payload: globalState.cartCount + 1 });
    }, 1000);
    setTimeout(() => {
      setAdded(false);
    }, 1500);
  };
  return (
    <StyledPriceTag
      disabled={adding || added}
      onClick={() => handleClick()}
      primary
      added={added}
      {...rest}
    >
      {added ? (
        <FontAwesomeIcon icon={faCheck} />
      ) : (
        <>
          {adding ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <>
              {children}
              <FontAwesomeIcon
                style={{ marginLeft: 5 + 'px' }}
                size="xs"
                icon={faPlus}
              />
            </>
          )}
        </>
      )}
    </StyledPriceTag>
  );
};

export default PriceTag;
